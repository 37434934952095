import React from "react";
import NavBar from "../components/Nabar";
import Footer from "../components/Footer";
import Verify from "../components/Verification/verify";


const VerificationPage = ()=>{
    return (
        <>
            <NavBar />
            <Verify />
            <Footer />
        </>
    )
};

export default VerificationPage;